import { Dispatch } from 'redux';
import { getFile } from '.';
import { STRUCTURE_ERROR } from './types';

const exportXlsxAction = async (dispatch :Dispatch, data: any) => {
  const url = '/export';
  const response = await getFile(STRUCTURE_ERROR, url, dispatch, data);

  console.log(response);
  return response;
};

export default exportXlsxAction;
