import React, { useEffect, useState } from 'react';
import { useForm, get, FieldValues } from 'react-hook-form';
import { saveAs } from 'file-saver';
import { useSelector, useDispatch } from 'react-redux';
import exportXlsxAction from '../../actions/export';
import { getAllStructuresAction } from '../../actions/structures';
import { ErrorField, StyledSelect } from '../../lib/HooksFormFields';
import { IStructure } from '../../types/structure';
import styles from './ExportModal.module.scss';

const exportTypes = [
  {
    value: 'annualization',
    label: 'Annualisation',
  },
  {
    value: 'common-pay-elements',
    label: 'Elements de paie commun',
  },
  {
    value: 'monthly-hours',
    label: 'Suivi heures',
  },
];

const ExportModal = () => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { structuresList } = useSelector((store: any) => store.structureReducer);
  const {
    list,
  } = useSelector((store: any) => store.shiftsReducer);

  const {
    control,
    reset,
    setValue,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<FieldValues>();

  const form = watch();
  const structuresOptions = structuresList?.map(
    (s: IStructure) => ({ value: s._id, label: s.name }),
  );

  async function onSubmit(values: FieldValues) {
    setIsLoading(true);
    const data = {
      type: values.type,
      startDate: values.period.startDate,
      endDate: values.period.endDate,
      structure: values.structure,
    };
    try {
      const res : any = await exportXlsxAction(dispatch, data);

      if (res) {
        const blob = new Blob([res]);
        const type = exportTypes?.find((t: any) => t.value === values.type)?.label;
        const structure = structuresOptions?.find((t: any) => t.value === values.structure)?.label;
        const period = list?.periods?.find(
          (t: any) => t.value?.startDate === values.period.startDate,
        )?.label;
        const filename = `${type}-${structure}-${period}.xlsx`;
        // Use file-saver to save the blob
        saveAs(blob, filename);
      }
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    getAllStructuresAction(dispatch);
  }, []);

  useEffect(() => {
    const foundPeriod = list?.periods?.find((p:any) => p.value?.isCurrent);
    if (foundPeriod) {
      reset({
        type: 'annualization',
        structure: localStorage.getItem('structure'),
        period: foundPeriod?.value,
      });
    }
  }, [list?.periods]);

  return (
    <div className={styles.modale}>
      <h2>
       Export
      </h2>
      <div className={styles.types}>
        {exportTypes.map((t) => (
          <button
            key={t.value}
            type='button'
            className={form?.type === t.value ? styles.active : ''}
            onClick={() => setValue('type', t.value)}
          >
            {t.label}
          </button>
        ))}
      </div>
      {typeof get(errors, 'type')?.message === 'string' && (
        <ErrorField message={get(errors, 'type')?.message || ''}/>
      )}
      <div className={styles.form}>
       <div className={styles['container-field']}>
         <StyledSelect
            name="structure"
            control={control}
            options={structuresOptions}
            rules={{
              required: 'la structure est requise',
            }}
         />
        {typeof get(errors, 'structure')?.message === 'string' && (
          <ErrorField message={get(errors, 'structure')?.message || ''}/>
        )}
      </div>
      <div className={styles['container-field']}>
         <StyledSelect
            name="period"
            control={control}
            options={list.periods}
            rules={{
              required: 'la période est requise',
            }}
         />
        {typeof get(errors, 'period')?.message === 'string' && (
          <ErrorField message={get(errors, 'period')?.message || ''}/>
        )}
      </div>
      {!isLoading
        ? <div className={styles.actions}>
          <button
            type="button"
            onClick={handleSubmit(onSubmit)}
          >
            Exporter
          </button>
        </div>
        : <div className={styles.actions}>
          Export en cours...
        </div>
      }
    </div>
    </div>
  );
};

export default ExportModal;
