/* eslint-disable no-restricted-syntax */
import {
  GET_ALL_SHIFTS,
  POST_SHIFT,
  SHIFT_LOADING,
  SHIFT_ERROR,
  GET_SHIFTS_LIST,
  SET_WEEK_VIEW,
  GET_MEMBER_HOME,
  POST_SHIFT_VALIDATION,
  POST_WEEKS_OPTIONS,
  SET_SHIFT_MODALE,
  POST_WEEK,
  DELETE_WEEK,
  POST_SHIFT_ERROR,
  SET_PLANNING_VIEW,
  POST_SHIFT_ALERT,
} from '../actions/types';
import { ActionReducer } from '../types';
import { FavioriteWeek, IShift } from '../types/shift';

interface ShiftState {
  list: any | null,
  shiftsList: IShift[],
  isLoading: string[],
  favioritesWeeks: FavioriteWeek[],
  error: string | null,
  home: any | null,
  isWeekView: boolean,
  shift: IShift | null,
  planningView: string,
  shiftsErrors: string[] | null,
  shiftsAlerts: string[] | null,
}

const DEFAULT_STATE: ShiftState = {
  list: {},
  shiftsList: [],
  isLoading: [],
  favioritesWeeks: [],
  error: null,
  home: null,
  shift: null,
  planningView: 'global',
  isWeekView: false,
  shiftsErrors: null,
  shiftsAlerts: null,
};

// eslint-disable-next-line default-param-last
const shiftsReducer = (state = DEFAULT_STATE, action: ActionReducer) => {
  let updatedState = {};
  const { shiftsList } = state;
  const { home } = state;
  const weeks = state.favioritesWeeks || [];
  switch (action.type) {
    case POST_WEEKS_OPTIONS:
      updatedState = {
        ...state,
        favioritesWeeks: action.payload,
      };
      break;
    case POST_WEEK:
      updatedState = {
        ...state,
        favioritesWeeks: [...weeks, action.payload],
      };
      break;
    case DELETE_WEEK:
      updatedState = {
        ...state,
        favioritesWeeks: weeks.filter((w : FavioriteWeek) => w._id !== action.payload?._id),
      };
      break;
    case GET_ALL_SHIFTS:
      updatedState = {
        ...state,
        shiftsList: action.payload,
        isLoading: state.isLoading.filter((d) => d !== action.type),
      };
      break;
    case POST_SHIFT:
      updatedState = {
        ...state,
        shiftsErrors: null,
        shiftsList: [
          ...shiftsList.filter(
            (s) => {
              if (action.payload?.data?.user && s.user !== action.payload?.data?.user) {
                return s;
              }
              if (action.payload?.data?.line && s.line !== action.payload?.data?.line) {
                return s;
              }
              return null;
            },
          ),
          ...action.payload.shifts,
        ],
        isLoading: state.isLoading.filter((d) => d !== action.type),
      };
      break;
    case SHIFT_LOADING:
      updatedState = {
        ...state,
        isLoading: [...state.isLoading, action.payload],
      };
      break;
    case GET_MEMBER_HOME:
      updatedState = { ...state, home: action.payload };
      break;
    case POST_SHIFT_VALIDATION:
      for (const shift of action.payload) {
        const index = home.shifts.findIndex((s : IShift) => s._id === shift._id);
        if (index !== -1) {
          home.shifts[index] = { ...shift, isValid: true };
        }
      }
      updatedState = { ...state, home };
      break;
    case SET_WEEK_VIEW:
      updatedState = { ...state, isWeekView: action.payload };
      break;
    case SET_PLANNING_VIEW:
      updatedState = {
        ...state,
        planningView: action.payload,
      };
      break;
    case GET_SHIFTS_LIST:
      updatedState = { ...state, list: action.payload };
      break;
    case SET_SHIFT_MODALE:
      updatedState = { ...state, shift: action.payload };
      break;
    case SHIFT_ERROR:
      updatedState = { ...state, error: action.payload };
      break;
    case POST_SHIFT_ALERT:
      updatedState = { ...state, shiftsAlerts: action.payload || null };
      break;
    case POST_SHIFT_ERROR:
      updatedState = { ...state, shiftsErrors: action.payload || null };
      break;
    default:
      updatedState = { ...state };
      break;
  }
  return updatedState;
};

export default shiftsReducer;
