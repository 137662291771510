import React, { useState, useEffect } from 'react';
import { useForm, FormProvider, FieldValues } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { FaPen } from 'react-icons/fa';
import { BsCheck } from 'react-icons/bs';
import { useNavigate, useParams } from 'react-router-dom';

import Header from '../../components/Header';
import UserForm from '../../components/User/UserForm';
import UserInfos from '../../components/User/UserInfos';

import styles from './User.module.scss';
import { createUserAction, getUserAction, updateUserAction } from '../../actions/users';
import { getAllStructuresAction } from '../../actions/structures';
import { GET_CONTRACT } from '../../actions/types';
import { getUserContractsAction } from '../../actions/contract';

const User = ({ isCreate = false }:{ isCreate?: boolean }) => {
  const [isEdit, setIsEdit] = useState(isCreate);
  const id = useParams()?.id;
  const navigate = useNavigate();

  const { user, error, filters } = useSelector((store: any) => store.usersReducer);
  const dispatch = useDispatch();
  const methods = useForm();
  const onSubmit = async (data : FieldValues) => {
    const formData = { ...data };
    methods.clearErrors('email');
    methods.clearErrors('code');
    if (data?.picture?._id) {
      formData.picture = data?.picture._id;
    }
    if (formData?.role === 'Admin' || formData.role === 'Owner') {
      formData.structure = null;
    }
    if (user?._id) {
      if (formData?.contract && !formData?.contract?.isAnnualized) {
        formData.contract.annualizedHours = null;
      }
      const res = await updateUserAction(dispatch, formData, user._id);
      if (res.data?.user) {
        setIsEdit(false);
      }
      return null;
    }
    const res = await createUserAction(dispatch, formData);
    if (res?.data) {
      return navigate('/admin/user');
    }
    return null;
  };

  useEffect(() => {
    getAllStructuresAction(dispatch);
  }, []);

  useEffect(() => {
    const userData = user ? { ...user } : {
      isActive: true,
      contract: {
        isAnnualized: false,
      },
    };
    if (user?._id) {
      if (userData?.structure?._id) {
        userData.structure = userData.structure._id;
      }
      if (userData?.contract?.startDate) {
        userData.contract.startDate = userData?.contract.startDate.split('T')[0];
      }
      if (userData?.contract?.endDate) {
        userData.contract.endDate = userData?.contract.endDate.split('T')[0];
      }
    } else if (filters.structure) {
      userData.structure = filters.structure;
    }
    methods.reset(userData);
    dispatch({
      type: GET_CONTRACT,
      payload: null,
    });
  }, [user]);

  useEffect(() => {
    if (id) {
      getUserAction(dispatch, id);
      getUserContractsAction(dispatch, id);
    }
  }, [id]);

  useEffect(() => {
    if (error?.data?.error === 'That email address is already in use.' || error?.data?.error === 'La valeur du ou des champs suivant est déjà utilisé : email.') {
      methods.setError(
        'email',
        {
          type: 'custom',
          message: 'l\'email est déja utilisé',
        },
      );
    }
    if (error?.data?.error === 'That code is already in use.') {
      methods.setError(
        'code',
        {
          type: 'custom',
          message: 'le matricule est déja utilisé',
        },
      );
    }
  }, [error]);

  return (
    <div className={styles.container}>
      <Header name='Mon profil'/>
      <div className={styles.content}>
      {!isEdit
        ? <button className={styles.edit} onClick={() => setIsEdit(true)}>
          <div className={styles.icon}>
            <FaPen />
          </div>
          <span>MODIFIER LES INFOS</span>
        </button>
        : <button className={styles.edit} onClick={methods.handleSubmit(onSubmit)}>
          <div className={styles.icon}>
            <BsCheck size={28}/>
          </div>
          <span>Valider les modifications</span>
        </button>
      }
      <FormProvider {...methods} >
        {isEdit
          ? <UserForm
            onSubmit={(obj: any) => onSubmit(obj)}
          />
          : <UserInfos
            onSubmit={(obj: any) => onSubmit(obj)}
          />}
        </FormProvider>
      </div>
    </div>
  );
};

export default User;
